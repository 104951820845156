.invitation-container {
    max-width: 500px;
    margin-left: 0;
}


/*if the Browser Window is 800px wide or less*/
@media only screen and (max-width: 575px) {
    .verticalAlignCeckbox{
        margin-left: 0;
    }
}