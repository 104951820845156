.doppelaufstellung-form-item {
    font-size: 0.45em !important;
}

.numberCircle {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 2px;

    background: red;
    border: 2px solid #666;
    color: #666;
    text-align: center;

    font-size: 1.5em;
    
    display: inline-block;
}

.wertung{
    border: 2px solid #666;
    border-radius: 50%;
    width: 1.75em;
    height: 1.75em;
    display: inline-block;
    color: #666;
    font-weight: bold;
    font-size: 1.4em;
}

.doppelaufstellung-box{
    margin-right: 1.5em;
    margin-bottom: 1.5em;

    padding: 0.5em;
    
    border-radius: 15px;

}

.pinned_doppel{
    background-color: #faf366 !important;
    border: 4px solid #fad902 !important;
}

.banned_doppel{
    background-color: #5d5c5c !important;
    border: 4px solid #050505 !important;
}

.btn-pinned{
    color: #efeb00;
    background-color: #f5f6e5;
    border-color: #c6c203;
}
.btn-pinned:hover{
    color: #f5f6e5;
    background-color: #efeb00;
    border-color: #c6c203;
}
.btn-pinned:focus{
    color: #efeb00;
    background-color: #f5f6e5;
    border-color: #c6c203;
    box-shadow: 0 0 0 .2rem rgb(241, 218, 5);
}

.btn-banned{
    color: #f50303;
    background-color: #790202;
    border-color: #c40101;
}
.btn-banned:hover{
    color: #790202;
    background-color: #f50303;
    border-color: #c40101;
}
.btn-banned:focus{
    color: #f50303;
    background-color: #790202;
    border-color: #c40101;
    box-shadow: 0 0 0 .2rem rgb(131, 52, 52);
}

.doppel-austellung-size{
    width: 100%;
}

.doppelaustellung-table{
    margin: 0 auto;
    width: 100%;
}

.doppelaufstellung-btn{
    font-size: 0.9em;
}

.switch-icon {
    font-size: 2.25em;
}

.rotate-icon{
    font-size: 1.5em;
}

.doppel-header-icon{
    font-size: 1.8em;
}

.doppel-aufstellung{
    font-size:  1.1em;
    display: block;
    width: 100%;
}

/*Das muss so kompliziert sein, da die font-size sonst aus der 1000er Media Query mit * { ... aus der App.css ueberschrieben wird*/
/*die Regel greit uer alle span-Elemente, die sich innerhalb eines divs mit der style-class doppel-austellung-size befinden*/
/*alternative waere gewesen: die style-klasse an die einzelnen span-Elemente einzeln direkt ran zu haengen. das fand ich unschoen/umstaendlich*/
@media (max-width: 600px) {
    div.doppel-austellung-size span {
        font-size:  1.25em !important;
    }

    .doppel-header-icon{
        font-size: 2.1em;
    }
}


.doppelaufstellung-bewertung-minus3{
    
    background-color: #e70325;
    color: #4c000c;
    font-weight: bold;
    
   
}

.doppelaufstellung-bewertung-minus2{
    background-color: #ff6f71;
    color: #4c000c;
    font-weight: bold;
}

.doppelaufstellung-bewertung-minus1{
    background-color: #fdd4d8;
    color: #4c000c;
}

.doppelaufstellung-bewertung-0{
    background-color: unset;
}

.doppelaufstellung-bewertung-1{
    background-color: #DEFFDB;
    color: #003822;
    font-weight: bold;
}

.doppelaufstellung-bewertung-2{
    background-color: #9EF0A9;
    color: #003822;
    font-weight: bold;
}

.doppelaufstellung-bewertung-3{
    background-color: #35F04E;
    color: #003822;
    font-weight: bold;

}



.doppel-neutral{
    background-color: #d6d6d6;
    border: 2px solid #777776;
}
.doppel-gut{
    background-color: #53e768;
    border: 2px solid #599401;
}
.doppel-mittel-gut{
    background-color: #b7f5a7;
    border: 2px solid #6bb009;
}
.doppel-bisschen-gut{
    background-color: #E4F8E3;
    border: 2px solid #a2c86d;
}

.doppel-schlecht{
    background-color: #c3203d;
    border: 2px solid #790217;
}
.doppel-mittel-schlecht{
    background-color: #ff9d9e;
    border: 2px solid #f15051;
}
.doppel-bisschen-schlecht{
    background-color: #ffd8d9;
    border: 2px solid #e9a4a9;
}



