
.trainingsplanungViewTable td{
  padding-left: 1em;
}
/*dass die letzte Zeile keine Border unten bekommt*/
table tr:last-child td {
  border-bottom: 0;
}

.spacer_line td{
  border-bottom:1px solid black;
  padding-top: 0.75em;
}