.doppelaufstellung-form-item {
    font-size: 0.45em !important;
}

/*CSS fuer die Liste der Doppelaufstellungen*/
.doppelaufstellungs-liste {
    display: flex; /* or inline-flex */
    flex-wrap: wrap;

    /*das hier tut den Trick, dass alle Elemente so weit sind, wie der breiteteste div*/
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.doppelaufstellung-box{
    width: 92%;
    margin-right: 1.5em;
    margin-bottom: 1.5em;
    padding: 0.5em;
    border-radius: 15px;
}

.doppel-austellung-size{
    width: 100%;
}

.doppelaustellung-table{
    margin: 0 auto;
    width: 100%;
}

.doppelaufstellung-btn{
    font-size: 0.9em;
}

.switch-icon {
    font-size: 2.25em;
}

.rotate-icon{
    font-size: 1.5em;
}

.doppel-header-icon{
    font-size: 1.8em;
}

.doppel-aufstellung{
    font-size:  1.1em;
    display: block;
    width: 100%;
}

@media (max-width: 1710px) {
    .doppelaufstellungs-liste {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 1450px) {
    .doppelaufstellungs-liste {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 1270px) {
    .doppelaufstellungs-liste {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}

/* On screens that are 1000px or less, */
/* Hier ist der Switch drin, der die Schriftgroesse und alles auch wieder groesser macht. Also von Mobil auf Desktop */
@media (max-width: 1150px) {
    .doppelaufstellungs-liste {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 1000px) {
    .doppelaufstellungs-liste {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 960px) {
    .doppelaufstellungs-liste {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}

/* On screens that are 850px or less, */
@media (max-width: 860px) {
    .doppelaufstellungs-liste {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 750px) {
    .doppelaufstellungs-liste {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

/*Das muss so kompliziert sein, da die font-size sonst aus der 1000er Media Query mit * { ... aus der App.css ueberschrieben wird*/
/*die Regel greit uer alle span-Elemente, die sich innerhalb eines divs mit der style-class doppel-austellung-size befinden*/
/*alternative waere gewesen: die style-klasse an die einzelnen span-Elemente einzeln direkt ran zu haengen. das fand ich unschoen/umstaendlich*/
@media (max-width: 600px) {
    .doppelaufstellungs-liste {
        grid-template-columns: 1fr 1fr 1fr;
    }
    
    div.doppel-austellung-size span {
        font-size:  1.25em !important;
    }

    .doppel-header-icon{
        font-size: 2.1em;
    }
}

/* On screens that are 400px or less, */
@media (max-width: 455px) {
    .doppelaufstellungs-liste {
        grid-template-columns: 1fr 1fr;
    }
}
/* max-width: On screens that are 250px or less */
/* min-width: On screens that are 250px or greater */
@media (max-width: 250px) {
    .doppelaufstellungs-liste {
        grid-template-columns: 1fr;
    }
}

.doppelaufstellung-bewertung-minus3{
    
    background-color: #e70325;
    color: #4c000c;
    font-weight: bold;
    
   
}

.doppelaufstellung-bewertung-minus2{
    background-color: #ff6f71;
    color: #4c000c;
    font-weight: bold;
}

.doppelaufstellung-bewertung-minus1{
    background-color: #fdd4d8;
    color: #4c000c;
}

.doppelaufstellung-bewertung-0{
    background-color: unset;
}

.doppelaufstellung-bewertung-1{
    background-color: #DEFFDB;
    color: #003822;
    font-weight: bold;
}

.doppelaufstellung-bewertung-2{
    background-color: #9EF0A9;
    color: #003822;
    font-weight: bold;
}

.doppelaufstellung-bewertung-3{
    background-color: #35F04E;
    color: #003822;
    font-weight: bold;

}



.doppel-neutral{
    background-color: #d6d6d6;
    border: 2px solid #777776;
}
.doppel-gut{
    background-color: #53e768;
    border: 2px solid #599401;
}
.doppel-mittel-gut{
    background-color: #b7f5a7;
    border: 2px solid #6bb009;
}
.doppel-bisschen-gut{
    background-color: #E4F8E3;
    border: 2px solid #a2c86d;
}

.doppel-schlecht{
    background-color: #c3203d;
    border: 2px solid #790217;
}
.doppel-mittel-schlecht{
    background-color: #ff9d9e;
    border: 2px solid #f15051;
}
.doppel-bisschen-schlecht{
    background-color: #ffd8d9;
    border: 2px solid #e9a4a9;
}



