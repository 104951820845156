
.error-no-margin-bottom-input {
  margin-bottom: 0 !important;
 
}

.error-no-margin-bottom-input .ant-form-item-explain-error{
  font-weight: normal !important;
}

.doppel-heim2{
  float: right;
}

/*Einzelne Spieler Edit */
/*CSS Klassen fuer die Tabelle pro Spieler. */
/*Extra sehr responsive, da sonst der hintere Button verschwindet (Zusagen oder Absagen) und so aussieht, als ob der Fehlen wurde*/

/*Die Reihenfolge ist wichtig. Das groesste muss oben stehen*/
@media (max-width: 600px) {
  .doppel-heim2 {
    float: left;
  }
}

.padding-top-responsive-match-edit{
  padding-top: 0rem;
}

@media (max-width: 479px) {
  .padding-top-responsive-match-edit{
    padding-top: 1.5rem;
  }
}