
.message {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
}
.message .message-icon {
    flex: 0 0 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25em;
    color: #fff;
    text-shadow: 1px 1px 10px #000;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}


.message-icon svg {
    font-size: 1.7em;
}

@media (max-width: 1000px) {
    .message-icon svg {
        font-size: 2.35em !important;
    }
}

.message .message-icon .fa-2x {
    box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.45);
}

.message-container {
    display: flex;
}
.message-container .message-content-container {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 15px;
    background-color: #fff;

    position: relative;
}
.message-container .message-content-container .message-header {
    font-size: 20px;
}
.message-container .message-content-container .message-body {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    color: #515151;
}
.message-container .message-content-container .message-action {
    position: absolute;
    bottom: 10px;
    right: 30px;
}

.warning-message {
    background-color: #ffc937;
    border-color: #ffc937;
}

.warning-message .message-content-container{
    background-color: #fff9f0;
}

.warning-message .message-action,
.warning-message .message-header{
    color: #f39b0e;
}

.info-message {
    background-color: #3598db;
    border-color: #3598db;
}

.info-message .message-content-container{
    background-color: #e6f2fa;
}

.info-message .message-action,
.info-message .message-header{
    color: #3598db;
}

.success-message {
    background-color: #7cbc77;
    border-color: #7cbc77;
}

.success-message .message-content-container{
    background-color: #f0f9ef;
}

.success-message .message-action,
.success-message .message-header{
    color: #43a046;
}

.error-message {
    background-color: #f98a89;
    border-color: #f98a89;
}

.error-message .message-content-container{
    background-color: #fff5f5;
}


.error-message .message-action,
.error-message .message-header{
    color: #e1374c;
}
