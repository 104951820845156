.doppel-heim2-filter{
  float: right;
}

/*Einzelne Spieler Edit */
/*CSS Klassen fuer die Tabelle pro Spieler. */
/*Extra sehr responsive, da sonst der hintere Button verschwindet (Zusagen oder Absagen) und so aussieht, als ob der Fehlen wurde*/




.padding-top-responsive{
  padding-top: 0rem;
}

.mannschaftslabel-responsive{
  margin-left: 8px;
}



/*Einzelne Spieler Edit */
/*CSS Klassen fuer die Tabelle pro Spieler. */
/*Extra sehr responsive, da sonst der hintere Button verschwindet (Zusagen oder Absagen) und so aussieht, als ob der Fehlen wurde*/
@media (max-width: 575px) {
  .mannschaftslabel-responsive{
    margin-left: 0em;
  }
  .doppel-heim2-filter {
    float: left;
  }
}

/*Die Reihenfolge ist wichtig. Das groesste muss oben stehen*/
@media (max-width: 436px) {
  .padding-top-responsive{
    padding-top: 1.5rem;
  }
}

