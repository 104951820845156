.planung_match_table_row_spacer td{
  padding-top: 2em;
}

.planung_match_table_row_spacer_small td{
  padding-top: 1em;
}

.planung_match_table_header_rows td{
  padding-left: 1em;
}

.planung_match_table_content_rows td{
  padding-left: 3em;
}

.add-icon{
  color: #007bff;
  cursor: pointer;
}

.add-icon:hover {
  color: #0069d9;
}

.delete-icon{
  color: #6c757d;
  cursor: pointer;
}

.delete-icon:hover {
  color: #5a6268;
}

/*dass die letzte Zeile keine Border unten bekommt*/
table tr:last-child td {
  border-bottom: 0;
}

.spacer_line_row td{
  border-bottom:2px solid black;
  padding-top: 2em;
}