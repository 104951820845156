.spieldatum{
    font-size: 1.0rem;
}

.heim {
    font-size: 0.8rem;
    font-weight: bold;
}

.gast {
    font-size: 0.8rem;
    font-weight: normal; 
}

@media (max-width: 1000px) {
    .heim {
        font-size: 0.65rem;
        font-weight: bold;
    }

    .gast {
        font-size: 0.65rem;
        font-weight: normal;
    }
}

.normal {
    font-size: 0.8rem;
    font-weight: normal;
}

.edit-button{
    margin-right: 20px;
}

/*Einzelne Spieler Edit */
/*CSS Klassen fuer die Tabelle pro Spieler. */
/*Extra sehr responsive, da sonst der hintere Button verschwindet (Zusagen oder Absagen) und so aussieht, als ob der Fehlen wurde*/

/*Die Reihenfolge ist wichtig. Das groesste muss oben stehen*/
@media (max-width: 500px) {
    .small-table td {
        padding-left: .60rem !important;
        padding-right: .60rem !important;
    }
    .responsiveHide{
        display: none;
    }
}

@media (max-width: 300px) {
    .small-table td {
        padding-left: .35rem !important;
        padding-right: .35rem !important;
    }
    .responsiveHide{
        display: none;
    }
}