.App {
}


html, body, form, fieldset, table, tr, td, img {

}

.parent-container-nav{
  padding-left: 1rem !important;
}

.parent-container{
  padding-left: 0.95rem !important;
}

@media (max-width: 1000px) {
  .parent-container-nav{
    padding-left: 0.5rem !important;
  }
  .parent-container{
    padding-left: 0.45rem !important;
  }
}

.table td{
  text-align: center;
  vertical-align: middle;
}

.table th{
  text-align: center;
  vertical-align: middle;
}

.left-aligined-table td{
  text-align: left !important;
  vertical-align: middle;
}

.left-aligined-table th{
  text-align: left !important;
  vertical-align: middle;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Increase all font sizes on mobile */
@media (max-width: 1000px) {
  * {
    font-size: 0.8rem;
    line-height: 1.5;
  }
  
  h1{
    font-size: 1.8rem;
  }
  h2{
    font-size: 1.5rem;
  }

  h3{
    font-size: 1.25rem;
  }

  h3{
    font-size: 1.0rem;
  }
  
  h4{
    font-size: 0.9rem;
  }

  h5{
    font-size: 0.8rem;
  }
  
  .ant-collapse-header{
    font-size: 1.0rem;
  }
}

@media (max-width: 1000px) {
  .btn {
    font-size: 0.8rem;
    padding:4px 6px;
  }
  .small-responsive-field{
    font-size: 1rem !important;
  }
  
  /*Die Beiden sind fuer die Select-(DropDown)-Boxen*/
  .ant-select-selection-item{
    font-size: 1rem !important;
  }
  
  .ant-select-item-option-content{
    font-size: 1rem !important;
  }
  
  .message-body{
    font-size: 0.9rem !important;
  }
  
}
.zugesagt-img{
  width: 42px;
}

@media (max-width: 1000px) {
  .zugesagt-img{
    width: 21px;
  }
}



@media (max-width: 1000px) {
  .navbar-brand
  {
    font-size: 1.8em !important;
  }

  .navbar .nav-link {
    font-size: 1.3em !important;
  }
  .navbar .dropdown-item{
    font-size: 1.0em !important;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  background-color: #F0F2F5 !important;
  position: absolute;
}

.busy-cursor{
  cursor:wait;
}


.form-container {
  max-width: 420px;
  margin: 0 auto;
  margin: 0 auto;
  margin-top: 40px;
}

.form-container-breit {
  max-width: 620px;
  margin: 0 auto;
  margin: 0 auto;
  margin-top: 40px;
}

.form-container-left-aligned {
  max-width: 420px;
  margin-top: 40px;
}

.form-container-breit-left-aligned {
  max-width: 620px;
  margin-top: 40px;
}

.content-container {
  max-width: 720px;
  margin: 0 auto;
  margin: 0 auto;
  margin-top: 40px;
}


.myLargeBtn{
  width: 150px;
}

.ant-form-explain{
  color: red;
}

